// ==========================================================================
// Carousel Set Royal Slider JS Module
// ==========================================================================

define(['jquery'], function($) {

  

  var CarouselSet = function() {

    var rsInstance = []; // For royal slider plus AMD

    var self = this;

    var defaultConfig = {
      sliderDrag: true,
      sliderTouch: true,
      transitionType: 'move',
      arrowsNav: true,
      arrowsNavAutoHide: false,
      controlNavigation: 'none',
      keyboardNavEnabled: true,
      allowCSS3: true,
      loop: true
    };

    function init(config) {

      if (!config) {
        config = defaultConfig;
      }

      var $carouselSet = $('.carousel-set .royal-slider, .carousel .royal-slider');
      $carouselSet.each(function(i) {
        // is there more than one child?
        var cCount = $(this).children().length;

        if (cCount > 1) {
          var rs = $(this).royalSlider(config);
          if (rs) {
            rsInstance[i] = {
              'find': $(this),
              'data': rs.data('royalSlider')
            };
          }
        }
      });

      $carouselSet.find('.btn[data-href]').click(function() {
        self.slideClickHandler(this);
      });

    }

    function slideClickHandler(ele) {
      window.location = $(ele).attr('data-href');
      return false;
    }

    self.init = init;
    self.slideClickHandler = slideClickHandler;
  };

  var carouselSet = new CarouselSet();
  carouselSet.init();
  return carouselSet;
});
